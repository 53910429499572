$(function () {

    var $navbarContainer = $('.nav-main');
    var navbarBreakpoint = 992;
    var $mainMenu = $('.nav-main__navbar');
    var $mainMenuItemLink = $('a.nav-main__link--level-1');
    var $mainMenuItemLinkNotDirect = $('a.nav-main__link--level-1.has-sub-menu');
    var $subMenu = $('.nav-main__list--level-2');
    var $overlay = $('.nav-main__overlay');

    function toggleMenu ($item, classClosed, classHidden) {
        if ($item.hasClass(classClosed)) {
            $item.removeClass(classClosed);
            setTimeout(function () {
                $item.removeClass(classHidden);
            }, 20);
        } else {
            $item.addClass(classHidden);
            $item.get(0).addEventListener('transitionend', function (e) {
                $item.addClass(classClosed);
            }, {
                capture: false,
                once: true,
                passive: false
            });
        }
    }

    function toggleMainMenuMobile () {
        // Slide main menu left-right by toggeling on mobile::
        toggleMenu($navbarContainer, 'mobile-closed', 'mobile-hidden');
        // Scroll within mobile menu, not within html/body:
        $('html, body').toggleClass('no-scroll');
    }

    function closeMainMenuMobile () {
        // Slide main menu left-right by toggeling on mobile::
        $navbarContainer.addClass('mobile-hidden');
        $navbarContainer.get(0).addEventListener('transitionend', function (e) {
            $navbarContainer.addClass('mobile-closed');
        }, {
            capture: false,
            once: true,
            passive: false
        });
        // Scroll within mobile menu, not within html/body:
        $('html, body').removeClass('no-scroll');
    }

    function closeSubMenu () {
        $subMenu.addClass('closed hidden');
    }

    $('.main-nav-toggler--main').click(function () {
        toggleMainMenuMobile();
    });

    $('.eos-api-btn').click(function (e) {
        e.preventDefault();
        closeMainMenuMobile();
    });

    $mainMenuItemLinkNotDirect.on('mouseenter', function (e) {
        if (window.innerWidth >= navbarBreakpoint) {
            toggleMenu($(this).parent().find($subMenu), 'closed', 'hidden');
        }
    });

    $mainMenuItemLinkNotDirect.click(function (e) {
        e.preventDefault();
        closeSubMenu();
        toggleMenu($(this).parent().find($subMenu), 'closed', 'hidden');
    });

    $mainMenuItemLink.on('mouseenter', function (e) {
        closeSubMenu();
        $mainMenuItemLink.parent().removeClass('active');
        $(this).parent().addClass('active');
        if (window.innerWidth >= navbarBreakpoint) {
            $overlay.addClass('active');
            $(this).find('> a').click();
        }
    });

    $(document).mousemove(function (e) {
        if (!$mainMenu.is(e.target) && $mainMenu.has(e.target).length === 0) {
            $overlay.removeClass('active');
            closeSubMenu();
        }
    });
});
